<template>
  <div class="slider">
    <div class="slider-header">
      <img :src="logoImg[formLink]" alt="" />
    </div>
    <div class="silder-menu">
      <el-menu
        :router="true"
        class="el-menu-vertical"
        @open="handleOpen"
        @close="handleClose"
        background-color="#15104B"
        :default-active="defaultActive"
        text-color="#ABACC3"
        active-text-color="#fff">
        <MenuItem :route="routesInfo"></MenuItem>
      </el-menu>
    </div>
  </div>
</template>
<script>
import router from '@/router'
import MenuItem from './MenuItem.vue'
import { mapState } from 'vuex'
import { getFrom } from '@/utils/tools'
export default {
  components: { MenuItem },
  data() {
    return {
      logoImg: {
        'app.usadrop.com': require('@/assets/imgs/guide/menuLogo.png'),
        'vn.usadrop.com': require('@/assets/imgs/guide/SliceSiderBarLogo.png')
      },
      formLink: getFrom(),
      routesInfo: []
    }
  },
  computed: {
    ...mapState(['userInfo']),
    defaultActive() {
      return this.$route.path
    }
  },
  created() {
    this.dispatch = this.$store.dispatch
    this.initializeRoutes()
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    initializeRoutes() {
      this.routesInfo = router.options.routes
      this.filterRoutes()
    },
    filterRoutes() {
      this.$store
        .dispatch('member/GetMemberVoMe')
        .then((res) => {
          const ownBizTypes = res.Result.OwnBizTypes || []
          this.routesInfo = this.routesInfo.filter((route) => {
            if (route.path === '/PaymentOrders' && !ownBizTypes.includes(3)) {
              return false
            }
            if (route.path === '/ProfitStatement' && !ownBizTypes.includes(2)) {
              return false
            }
            return true
          })
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>
<style scoped lang="scss">
.slider {
  width: 260px;
  height: 100vh;
  background: #15104b;
  padding: 0 30px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .slider-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    height: 70px;
    padding: 20px 0;
    img {
      width: 159px;
      height: 50px;
      margin-left: 9px;
    }
  }
  .silder-menu {
    .menu-item {
      display: flex;
      align-items: center;
      font-size: 18px;
      padding-left: 10px;
      .menu-item-image {
        margin-right: 20px;
        img {
          height: 32px;
          width: 32px;
        }
      }
    }
    ::v-deep .el-menu {
      border: none;
      margin-left: -16px;
      .el-menu-item.is-active {
        .menu-item {
          background-color: #4d4e71 !important;
          border-radius: 4px 4px 4px 4px;
        }
      }
    }
  }
}
</style>
