var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "feed-process-result" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("span", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("SupportTicket.ProcessResult"))),
          ]),
          _c(
            "el-button",
            {
              staticStyle: { background: "#3f6aff", border: "none" },
              attrs: { size: "small", round: "", type: "primary" },
              on: {
                click: function ($event) {
                  _vm.showReplyDialog = true
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("SupportTicket.Reply")) + " ")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", round: "", type: "primary" },
              on: { click: _vm.onProblemSolved },
            },
            [_vm._v(" " + _vm._s(_vm.$t("SupportTicket.ProblemSolved")) + " ")]
          ),
        ],
        1
      ),
      _c("span", { staticClass: "reply-title" }, [
        _vm._v(
          " Reply：" +
            _vm._s(_vm.formModel.lastProcessResult.Reply || "-") +
            " "
        ),
      ]),
      _c("span", { staticClass: "reply-content-title" }, [
        _vm._v(_vm._s(_vm.$t("SupportTicket.Feedback")) + ":"),
      ]),
      _c("el-input", {
        attrs: {
          disabled: "",
          type: "textarea",
          value: _vm.formModel.lastProcessResult.Feedback,
          rows: "3",
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showReplyDialog,
            width: "40%",
            "show-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showReplyDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formRef",
              attrs: { "label-position": "top", model: _vm.formModel },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("SupportTicket.Reply"),
                    prop: "Reply",
                    rules: {
                      required: true,
                      message: "Please Input Reply",
                      trigger: "blur",
                    },
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 4 },
                    model: {
                      value: _vm.formModel.Reply,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModel, "Reply", $$v)
                      },
                      expression: "formModel.Reply",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("SupportTicket.ImageUrl") } },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action: _vm.action,
                        "list-type": "picture-card",
                        headers: _vm.headers,
                        "on-remove": _vm.handleRemove,
                        limit: 20,
                        "on-success": (res, file) => {
                          _vm.handleUploadSuccess(res, file)
                        },
                      },
                    },
                    [_c("i", { staticClass: "el-icon-plus" })]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "button-close",
                  attrs: { loading: _vm.loading, round: "" },
                  on: {
                    click: function ($event) {
                      _vm.showReplyDialog = false
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("SupportTicket.Close")) + " ")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "button-confirm",
                  attrs: { loading: _vm.loading, round: "", type: "primary" },
                  on: { click: _vm.onConfirm },
                },
                [_vm._v(" " + _vm._s(_vm.$t("SupportTicket.Confirm")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }