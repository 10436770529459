<template>
  <!-- 会员支付 -->
  <div class="payment">
    <div class="left">
      <div class="type">{{ $t('member.type') }}</div>
      <div>
        <el-select v-model="value" placeholder="Professional" class="Professional" @change="type">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="LinkStore">
        <div class="member_duration">{{ $t('member.duration') }}</div>
        <div class="Link_My_Store" @click="myStore" v-if="DiscountBindshop">
          {{ $t('member.LinkMyStore') }}
        </div>
      </div>
      <div class="radio" v-if="value == 1">
        <el-radio-group
          v-for="(item, index) in quarterList"
          :key="index"
          v-model="radio"
          @change="upgrade(item.PriceId, item.CycleType)"
        >
          <!-- 中间档 -->
          <!-- 月 -->
          <template v-if="item.CycleType == 0">
            <!-- 有优惠无绑定店铺 -->
            <template v-if="DiscountBindshop">
              <el-radio :label="1" class="radio1">
                {{ $t('member.month') }}
                <span class="Month1">{{ item.PriceText }}</span>
                <p class="LinkShopifyStore">
                  {{ $t('member.LinkShopifyStore') }}
                </p>
              </el-radio>
            </template>
            <!-- 有优惠绑定店铺 -->
            <template v-if="NoDiscountBindshop">
              <el-radio class="radio1" :label="1">
                {{ $t('member.month') }}
                <span class="Month_Price">{{ $t('member.money') }}{{ item.Price }}</span>
                <span class="Month_Mon"
                  ><span>{{ $t('member.money') }}</span
                  >{{ item.RealPrice }}{{ $t('member.MON') }}<span></span
                ></span>
                <div class="for_membership">{{ $t('member.first-time') }}</div>
                <p class="LinkShopifyStore">{{ $t('member.First_time') }}</p>
              </el-radio>
            </template>
            <!-- 无优惠 -->
            <template v-else>
              <el-radio
                :label="1"
                v-if="item.Discount == 0 && item.RefereeDiscount == 0"
                class="radio1"
                >{{ $t('member.month') }}
                <span class="Month1">{{ item.PriceText }}</span>
              </el-radio>
            </template>
          </template>
          <!-- 月 -->

          <!-- 季度 -->
          <template v-if="item.CycleType == 1">
            <template v-if="DiscountBindshop">
              <el-radio :label="2" class="radio2"
                >{{ $t('member.season') }}
                <span class="Season_price_text">{{ item.PriceText }}</span>
                <div class="Recommended_season">{{ $t('member.Recommended') }}</div>
                <span class="Season2">
                  ({{ $t('member.save')
                  }}{{ Number(quarterList[0].Price * 3 - quarterList[1].Price).toFixed(2) }})</span
                >
                <p class="LinkShopifySeason">
                  {{ $t('member.LinkShopifyStore') }}
                </p>
              </el-radio>
            </template>
            <template v-if="NoDiscountBindshop">
              <el-radio :label="2" class="radio2"
                >{{ $t('member.season') }}
                <div class="RecommendedTwo">{{ $t('member.Recommended') }}</div>
                <span class="Season_Price">{{ $t('member.money') }}{{ item.Price }}</span>
                <span class="Season_RealPrice"
                  ><span>{{ $t('member.money') }}</span
                  >{{ item.RealPrice }}{{ $t('member.3months') }}</span
                >
                <p class="LinkShopifySeason">
                  {{ $t('member.First_time') }}
                </p>
              </el-radio>
            </template>
            <template v-else>
              <el-radio
                :label="2"
                v-if="item.Discount == 0 && item.RefereeDiscount == 0"
                class="radio2"
                >{{ $t('member.season') }}
                <span class="Season1">{{ item.PriceText }}</span>
                <div class="Recommended">{{ $t('member.Recommended') }}</div>
                <span class="Season2">
                  ({{ $t('member.save')
                  }}{{ Number(quarterList[0].Price * 3 - quarterList[1].Price).toFixed(2) }})</span
                >
              </el-radio>
            </template>
          </template>
          <!-- 季度 -->

          <!-- 年 -->
          <template v-if="item.CycleType == 2">
            <template v-if="DiscountBindshop">
              <el-radio :label="3" class="radio3"
                >{{ $t('member.year') }}
                <span class="Year_price_text">{{ item.PriceText }}</span>
                <span class="YearTwo">
                  ({{ $t('member.save')
                  }}{{ Number(quarterList[0].Price * 12 - quarterList[2].Price).toFixed(2) }})</span
                >
                <p class="LinkShopifyYear">
                  {{ $t('member.LinkShopifyStore') }}
                </p>
              </el-radio>
            </template>
            <template v-if="NoDiscountBindshop">
              <el-radio :label="3" class="radio3"
                >{{ $t('member.year') }}
                <span class="Year_Price">{{ $t('member.money') }}{{ item.Price }}</span>
                <span class="Year_RealPrice"
                  ><span>{{ $t('member.money') }}</span
                  >{{ item.RealPrice }}{{ $t('member.Yearly') }}</span
                >
                <p class="LinkShopifyYear">{{ $t('member.First_time') }}</p>
              </el-radio>
            </template>
            <template v-else>
              <el-radio
                :label="3"
                v-if="item.Discount == 0 && item.RefereeDiscount == 0"
                class="radio3"
                >{{ $t('member.year') }}
                <span class="Year1">{{ item.PriceText }}</span>
                <span class="YearTwo">
                  ({{ $t('member.save')
                  }}{{ Number(quarterList[0].Price * 12 - quarterList[2].Price).toFixed(2) }})</span
                >
              </el-radio>
            </template>
          </template>
          <!-- 年 -->
        </el-radio-group>
      </div>

      <!-- 最高级 -->
      <div class="radio" v-if="value == 2">
        <el-radio-group
          v-for="item in yearList"
          :key="item.index"
          v-model="radio"
          @change="upgrade(item.PriceId, item.CycleType)"
        >
          <!-- 月 -->
          <template v-if="item.CycleType == 0">
            <template v-if="DiscountBindshop">
              <el-radio :label="1" class="radio1"
                >{{ $t('member.month') }}
                <span class="Month1">{{ item.PriceText }}</span>
                <p class="LinkShopifyStore">
                  {{ $t('member.LinkShopifyStore') }}
                </p>
              </el-radio>
            </template>
            <template v-if="NoDiscountBindshop">
              <el-radio class="radio1">
                {{ $t('member.month') }}
                <span class="Month_Price">{{ $t('member.money') }}{{ item.Price }}</span>
                <span class="Month_Mon"
                  ><span>{{ $t('member.money') }}</span
                  >{{ item.RealPrice }}{{ $t('member.MON') }}<span></span
                ></span>
                <p class="LinkShopifyStore">{{ $t('member.First_time') }}</p>
              </el-radio>
            </template>
            <template v-else>
              <el-radio
                :label="1"
                v-if="item.Discount == 0 && item.RefereeDiscount == 0"
                class="radio1"
                >{{ $t('member.month') }}
                <span class="Month1">{{ item.PriceText }}</span>
              </el-radio>
            </template>
          </template>
          <!-- 月 -->

          <!-- 季度 -->
          <template v-if="item.CycleType == 1">
            <template v-if="DiscountBindshop">
              <el-radio :label="2" class="radio2"
                >{{ $t('member.season') }}
                <span class="Season1_price_text">{{ item.PriceText }}</span>
                <div class="Recommended_discount">{{ $t('member.Recommended') }}</div>
                <span class="Season2">
                  ({{ $t('member.save')
                  }}{{ Number(quarterList[0].Price * 3 - quarterList[1].Price).toFixed(2) }})</span
                >
                <p class="LinkShopifyStore">
                  {{ $t('member.LinkShopifyStore') }}
                </p>
              </el-radio>
            </template>
            <template v-if="NoDiscountBindshop">
              <el-radio :label="2" class="radio2"
                >{{ $t('member.season') }}
                <div class="RecommendedTwo">{{ $t('member.Recommended') }}</div>
                <span class="Season_Price">{{ $t('member.money') }}{{ item.Price }}</span>
                <span class="Season_RealPrice"
                  ><span>{{ $t('member.money') }}</span
                  >{{ item.RealPrice }}{{ $t('member.3months') }}</span
                >
                <p class="LinkShopifySeason">
                  {{ $t('member.First_time') }}
                </p>
              </el-radio>
            </template>
            <template v-else>
              <el-radio
                :label="2"
                v-if="item.Discount == 0 && item.RefereeDiscount == 0"
                class="radio2"
                >{{ $t('member.season') }}
                <span class="Season1">{{ item.PriceText }}</span>
                <div class="Recommended_two">{{ $t('member.Recommended') }}</div>
                <span class="Season2"
                  >({{ $t('member.save')
                  }}{{ Number(quarterList[0].Price * 3 - quarterList[1].Price).toFixed(2) }})</span
                >
              </el-radio>
            </template>
          </template>
          <!-- 季度 -->

          <!-- 年 -->
          <template v-if="item.CycleType == 2">
            <template v-if="DiscountBindshop">
              <el-radio :label="3" class="radio3"
                >{{ $t('member.year') }}
                <span class="Year1_discount">{{ item.PriceText }}</span>
                <span class="YearTwo">
                  ({{ $t('member.save')
                  }}{{ Number(quarterList[0].Price * 12 - quarterList[2].Price).toFixed(2) }})</span
                >
                <p class="LinkShopifyYear">
                  {{ $t('member.LinkShopifyStore') }}
                </p>
              </el-radio>
            </template>
            <template v-if="NoDiscountBindshop">
              <el-radio :label="3" class="radio3"
                >{{ $t('member.year') }}
                <span class="Year_Price">{{ $t('member.money') }}{{ item.Price }}</span>
                <span class="Year1"
                  ><span>{{ $t('member.money') }}</span
                  >{{ item.RealPrice }}{{ $t('member.Yearly') }}</span
                >
                <p class="LinkShopifyYear">{{ $t('member.First_time') }}</p>
              </el-radio>
            </template>
            <template v-else>
              <el-radio
                :label="3"
                v-if="item.Discount == 0 && item.RefereeDiscount == 0"
                class="radio3"
                >{{ $t('member.year') }}
                <span class="Year_one">{{ item.PriceText }}</span>
                <span class="YearTwo">
                  ({{ $t('member.save')
                  }}{{ Number(quarterList[0].Price * 12 - quarterList[2].Price).toFixed(2) }})</span
                >
              </el-radio>
            </template>
          </template>
          <!-- 年 -->
        </el-radio-group>
      </div>
      <div class="method">{{ $t('member.method') }}</div>
      <div class="payWays" v-if="UserList.IsAdmin == true">
        <div v-for="(payItem, payIndex) in tableData" :key="payIndex" class="flex">
          <el-checkbox
            @change="changePay(payIndex, $event)"
            v-model="payItem.checked"
            class="checkbox"
          >
            <div class="card" :class="payItem.checked ? 'choosed' : ''">
              <img :src="payItem.img" alt="" />
            </div>
          </el-checkbox>
        </div>
      </div>
      <div class="CheckAgreement">
        <p class="agree" style="margin-top: 5px">
          <el-checkbox v-model="checkedBox" type="checkbox" />
          {{ $t('register.agree') }}
          <span v-if="this.formLink === 'vn.usadrop.com'">
            <a href="https://vn.usadrop.com/terms-and-conditions/" class="Agree1" target="_blank">{{
              $t('register.user')
            }}</a>
          </span>
          <span v-else>
            <a href="https://usadrop.com/terms-and-conditions/" class="Agree1" target="_blank">{{
              $t('register.user')
            }}</a>
          </span>
          {{ $t('register.and') }}
          <span v-if="this.formLink === 'vn.usadrop.com'">
            <a href="https://vn.usadrop.com/privacy-policy/" class="Agree1" target="_blank">{{
              $t('register.privacy')
            }}</a>
          </span>
          <span v-else>
            <a href="https://usadrop.com/privacy-policy/" class="Agree1" target="_blank">{{
              $t('register.privacy')
            }}</a>
          </span>
        </p>
      </div>
    </div>
    <div class="right">
      <div class="Information">
        <i class="el-icon-s-operation"></i>
        {{ $t('member.confirmation') }}
      </div>
      <div class="head">
        <div class="Credit_Card">
          <span v-if="Information && Information.Number">{{ Information.Number }}</span>
        </div>
        <div class="message">
          <span>{{ $t('member.purchase') }}</span>
          <div v-if="userInfo && userInfo.LoginName" class="member_purchase">
            {{ userInfo.LoginName }}
          </div>
        </div>
        <div class="message">
          <span>{{ $t('member.membership') }}</span>
          <p v-if="value == 0" class="member_membership">{{ $t('member.basic') }}</p>
          <p v-if="value == 2" class="member_membership">{{ $t('member.growing') }}</p>
          <p v-if="value == 1" class="member_membership">{{ $t('member.professional') }}</p>
        </div>
        <div class="message">
          <span>{{ $t('member.opening') }}</span>
          <span v-if="productType == 0" class="member_opening">{{ $t('member.one') }}</span>
          <span v-if="productType == 1" class="member_opening">{{ $t('member.three') }}</span>
          <span v-if="productType == 2" class="member_opening">{{ $t('member.years') }}</span>
        </div>
        <div class="message">
          <span>{{ $t('member.subtotal') }}</span>
          <span v-if="CalculateFeeList && CalculateFeeList.RegularAmount" class="member_subtotal"
            >$
            {{
              Number(
                CalculateFeeList.RegularAmount -
                  CalculateFeeList.Discount -
                  CalculateFeeList.RefereeDiscount
              ).toFixed(2)
            }}</span
          >
        </div>
      </div>
      <div class="expiration">
        <span>{{ $t('member.date') }}</span>
        <p v-if="CalculateFeeList && CalculateFeeList.EndDate">
          {{ CalculateFeeList.EndDate }}
        </p>
      </div>
      <div class="price" v-if="CalculateFeeList && CalculateFeeList.RegularAmount">
        $
        {{
          Number(
            CalculateFeeList.RegularAmount -
              CalculateFeeList.Discount -
              CalculateFeeList.RefereeDiscount
          ).toFixed(2)
        }}
      </div>
      <div>
        <paypal
          v-show="selectPayType == 0"
          v-if="CalculateFeeList && CalculateFeeList.PriceId"
          :money="CalculateFeeList.PriceId"
          :planId="plan_id"
          style="width: 200px; margin-left: 40px; padding-top: 50px"
        ></paypal>
      </div>
    </div>
    <!-- 信用卡弹出框 -->
    <div class="paybox" v-if="Withdrawn">
      <div class="pay_content">
        <p class="pay_title">{{ $t('member.Card') }}<span @click="modify"></span></p>
        <div class="pay_items">
          <el-table
            :data="tableData1"
            class="pay_item"
            @row-click="noticeDetail"
            :row-class-name="setRowClassName"
            height="451"
          >
            <el-table-column width="500" class="pay_left_column">
              <div slot-scope="scope" class="pay_left">
                <span>{{
                  scope.row.Number.replace(/^(\d{4})\d+(\d{4})$/, '**** **** **** $2')
                }}</span>
              </div>
            </el-table-column>
            <el-table-column width="150">
              <div slot-scope="scope" class="pay_right">
                <el-button type="primary" plain @click="mODIFY(scope.row.CardId)"
                  >{{ $t('member.MODIFY') }}
                </el-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
        <div class="paybtn">
          <el-button type="primary" plain @click="newly">{{ $t('member.Newly') }}</el-button>
        </div>
      </div>
    </div>
    <div v-if="StripePopUp" class="StripeContentBox">
      <i class="el-icon-circle-close" @click="CloseStripePopUp"></i>
      <NewStripeMode :stripeone="StripeNumber"></NewStripeMode>
    </div>
    <div>
      <!-- 添加信用卡弹出框 -->
      <!--   <div class="creditcard" v-if="creditcard">
      <div class="card_content">
        <p class="card_title">
          {{ $t("member.Added") }}<span @click="Return"></span>
        </p>
        <div class="card_items">
          <div class="card_item">
            <p class="card_left">{{ $t("member.number") }}</p>
            <span class="card_Right"
              ><el-input
                v-model="input"
                auto-complete="off"
                :placeholder="$t('')"
              ></el-input
            ></span>
          </div>
          <div class="card_item">
            <span class="card_right1">
              <el-form>
                <span>
                  <el-form-item>
                    <el-input
                      type="number"
                      v-model="value1"
                      auto-complete="off"
                      :placeholder="$t('member.select')"
                      @change="aa"

                    ></el-input>
                  </el-form-item>
                </span>
                <span class="YearLeft">
                  <el-form-item>
                    <el-input
                      type="number"
                      v-model="value3"
                      auto-complete="off"
                      :placeholder="$t('member.Year')"
                      @change="getCurrentYear"
                    ></el-input>
                  </el-form-item>
                </span>
              </el-form>
            </span>
            <p class="Separation">/</p>
          </div>
        </div>
        <div class="card_item">
          <span class="card_right"
            ><el-input
              v-model="input1"
              :placeholder="$t('member.content1')"
            ></el-input
          ></span>
        </div>
        <div class="cardbtn">
          <el-button type="primary" class="Return" plain @click="Return">{{ $t("member.Return") }}</el-button>
          <button type="primary" class="added" @click="added">{{ $t("member.Pay") }}</button>
        </div>
      </div>
    </div> -->
    </div>
  </div>
</template>
<script>
import paypal from '@/components/PaypalSubscriptionSample.vue'
import NewStripeMode from '@/components/NewStripe.vue'
import { mapState } from 'vuex'
import { getFrom } from '@/utils/tools'
export default {
  data: () => {
    return {
      dispatch: null,
      formLink: getFrom(),
      radio: '',
      options: [
        {
          value: '1',
          label: 'Pro'
        },
        {
          value: '2',
          label: 'Plus'
        }
      ],
      value1: '', // 月
      tableData1: [],
      tableData: [
        {
          PayType: 0,
          img: require('@/assets/imgs/Bank-Transfer/pay1.png'),
          payname: '',
          checked: true
        },
        {
          PayType: 1,
          img: require('@/assets/imgs/Bank-Transfer/pay3.png'),
          payname: '',
          checked: false
        }
      ],
      value: '',
      /* value9: "", */
      paymentList: [], //会员等级
      CalculateFeeList: [], //会员升级费用
      productType: '', //商品类型
      selectPayType: null, //选中的支付方式
      Withdrawn: false, //信用卡弹出框列表
      creditcard: false, //新增信用卡弹出框
      input: '', // 卡号
      input1: '', // 信用卡安全码
      value3: '', // 年
      id: '',
      Information: {}, // 选中的信用卡信息
      SubmitList: [], // 提交会员升级费返回数据
      plan_id: '', // 提交PayPal支付的ID
      pickerOptions: {
        disabledDate(time) {
          //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return time.getTime() < Date.now() //选当前时间之前的时间
        }
      },
      year: '',
      ClickMyStore: false,
      checkedBox: false, //已读
      DiscountBindshop: false, // 有优惠无绑定店铺
      NoDiscountBindshop: false, // 有优惠绑定店铺
      NoDiscountNoBindshop: false, // 没有优惠绑定店铺
      StripePopUp: false, // Stripe弹窗
      UserList: []
    }
  },
  computed: {
    ...mapState(['theme', 'userInfo']),
    quarterList: function () {
      let arr = this.paymentList.filter((item) => {
        if (item.GradeId == 2) {
          return item
        }
      })
      // console.log(111,arr);
      return arr
    },
    yearList: function () {
      return this.paymentList.filter((item) => {
        if (item.GradeId == 3) {
          return item
        }
      })
    }
  },
  components: {
    paypal,
    NewStripeMode
  },
  created() {
    this.value = this.$route.params.id
    this.radio = this.$route.params.radio * 1
    this.dispatch = this.$store.dispatch
    const priceId = this.$route.params.priceId * 1
    this.upgrade(priceId, this.$route.params.cycleType)
  },
  mounted() {
    this.getMemberInfo()
    this.DisplayDicountHint()
    this.menterData()
  },
  methods: {
    menterData() {
      this.dispatch('member/GetMemberVoMe')
        .then((res) => {
          this.UserList = res.Result
        })
        .catch((err) => {
          console.log(err)
        })
    },
    /* 判断会员权益显示的内容 */
    DisplayDicountHint() {
      this.dispatch('member/DisplayDicountHintMe')
        .then((res) => {
          if (res.Result.HasDiscount == true && res.Result.HasBindShop == false) {
            this.DiscountBindshop = true
          }
          if (res.Result.HasDiscount == true && res.Result.HasBindShop == true) {
            this.NoDiscountBindshop = true
          }
          if (res.Result.HasDiscount == false && res.Result.HasBindShop == true) {
            this.NoDiscountNoBindshop = true
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    setRowClassName({ row, rowIndex }) {
      return this.$store.state.theme == 'dark' ? 'dark tr' : ''
    },
    aa() {
      if (this.value1 <= 12 && this.value1 >= 1) {
        return
      } else {
        this.$message.error(this.$t('member.fillMonth'))
        this.value1 = ''
      }
    },
    getCurrentYear() {
      var myDate = new Date()
      var tYear = myDate.getFullYear()
      var tMonth = myDate.getMonth()
      var currentDate = new Date(tYear, tMonth)
      //console.log(currentDate)
      if (this.value3.length > 2) {
        this.$message.error(this.$t('member.fillYear'))
        this.value3 = ''
      }
      var year = tYear.toString().substr(2, 2)
      var century = tYear - year
      let y = century + Number(this.value3)
      let m = Number(this.value1)
      if (m > 0) {
        m = m - 1
      }
      var expireDate = new Date(y, m)
      //console.log(expireDate)
      if (expireDate >= currentDate) {
        return
      } else {
        this.$message.error(this.$t('member.fillYear'))
        this.value3 = ''
      }
    },
    getMemberInfo() {
      let that = this
      that
        .dispatch('member/GetGradePriceListMe')
        .then((res) => {
          this.paymentList = res.Result
        })
        .catch((err) => {
          console.log(err)
        })
    },
    type() {
      this.radio = ''
    },
    upgrade(item, val) {
      this.productType = val
      let that = this
      that
        .dispatch('member/CalculateFeeMe', { PriceId: item })
        .then((res) => {
          //console.log(res);
          if (res.Success == true) {
            that.CalculateFeeList = res.Result
            console.log(res.Result.FirstAmount, '99999999999988888888888')
            /*this.$store.dispatch('setFirstAmount', res.Result.FirstAmount)
             that.paypalSubDate(res.Result.PriceId); */
          } else {
            this.$message.error(res.ErrMsg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    /* paypalSubDate(item) {
      let that = this;
      request({
        method: "post",
        url: apis.member.MemberShip,
        data: {
          PriceId: item,
        },
      })
        .then((res) => {
          console.log(res);
          that.plan_id = res.Result;
        })
        .catch((err) => {
          console.log(err);
        });
    }, */
    changePay(index, value) {
      this.tableData.map((item) => (item.checked = false))
      //  取消选中时 也是当前的支付方式
      this.selectPayType = this.tableData[index].PayType
      this.tableData[index].checked = true
      this.btn(index)
    },
    btn(item) {
      console.log(item, '963')
      this.selectPayType = item
      if (this.checkedBox == false) {
        return this.$message.error(this.$t('member.pleaseAgree'))
      }
      if (this.CalculateFeeList == '') {
        return this.$message.warning(this.$t('member.empty'))
      }
      if (item == 1) {
        console.log(1111)
        /* Stripe支付方式 */
        let that = this
        that
          .dispatch('member/CreateOrderByStripeMe', {
            PriceId: Number(that.CalculateFeeList.PriceId),
            PayType: Number(3)
          })
          .then((res) => {
            // console.log(res);
            if (res.Success == true) {
              this.StripePopUp = true
              this.StripeNumber = res.Result.StripeParam.ClientSecret
            } else {
              return this.$message(res.ErrMsg)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    modify() {
      this.Withdrawn = false
    },
    /* 关闭Stripe支付方式 */
    CloseStripePopUp() {
      this.StripePopUp = false
    },
    newly() {
      this.Withdrawn = false
      Airwallex.init({
        env: 'prod', // prod
        locale: 'en',
        origin: window.location.origin // Set up your event target to receive the browser events message
      })
    },
    // 删除信用卡
    mODIFY(id) {
      this.Withdrawn = false
      this.creditcard = true
      // this.id = id
      this.dispatch('member/LhMemberBankCardDelMe', {
        Id: id
      })
        .then((res) => {
          if (res.Success == true) {
            this.$message.success(this.$t('member.DeleteSucceeded'))
          } else {
            this.$message.error(this.$t('member.DeletionFailed'))
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    noticeDetail(row) {
      //console.log(row,'123456')
      this.Withdrawn = false
      //查看消息详情
      this.Information = row
      // 提交会员升级费
      let that = this
      that
        .dispatch('member/CreateOrderByAirWallexSubscriptionMe', {
          PriceId: Number(that.CalculateFeeList.PriceId),
          PayType: Number(5),
          CardId: Number(row.CardId)
        })
        .then((res) => {
          if (res.Success) {
            this.SubmitList = res
            //  this.converBtn();
            this.$router.push('/Airwallexsuccess')
          } else {
            this.$router.push('/Airwallexfail')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    CloseCreditcard() {
      this.creditcard = false
      this.Withdrawn = false
    },
    // 取消添加信用卡
    Return() {
      this.creditcard = false
      this.Withdrawn = true
    },
    // 确定添加信用卡
    added() {
      //console.log(this.id);
      if (this.input == '') {
        return this.$message.error(this.$t('member.cannot'))
      }
      if (this.value3 == '') {
        return this.$message.error(this.$t('member.expiration'))
      }
      if (this.value1 == '') {
        return this.$message.error(this.$t('member.Credit'))
      }
      if (this.input1 == '') {
        return this.$message.error(this.$t('member.code'))
      }
      let that = this
      that
        .dispatch('member/AddAsncMe', {
          id: Number(this.id),
          number: String(this.input),
          expMonth: Number(this.value1),
          expYear: Number(2022),
          cvc: String(this.input1)
        })
        .then((res) => {
          // console.log(res);
          if (res.Success == true) {
            this.creditcard = false
            this.btn(1)
            this.input = ''
            this.value3 = ''
            this.value1 = ''
            this.input1 = ''
          }
          // this.converBtn();
        })
        .catch((err) => {
          console.log(err)
        })
      this.creditcard = false
      this.Withdrawn = true
    },
    myStore() {
      this.$router.push('/Store')
    }
  }
}
</script>
<style>
/* .el-input--suffix .el-input__inner {
  background: none !important;
} */
</style>
<style scoped>
.StripeContentBox >>> .el-icon-circle-close {
  font-size: 26px;
  cursor: pointer;
  margin-left: 550px;
  margin-top: -15px;
}

.StripeContentBigBox {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
}

.StripeContentBox {
  width: 600px;
  height: auto;
  background: #e4e6ef;
  position: absolute;
  top: 100px;
  left: 160px;
  border-radius: 8px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
  padding-bottom: 25px;
  z-index: 9;
}

/* 隐私协议 */
.CheckAgreement {
  margin-top: 66px;
  text-align: center;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

/* 等级文案显示*/
.LinkShopifyStore {
  width: 100%;
  height: 22px;
  position: absolute;
  top: 23px;
  left: 56px;
  font-size: 14px;
  color: #32cd32;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.LinkShopifySeason {
  width: 100%;
  height: 22px;
  position: absolute;
  top: 23px;
  left: 56px;
  font-size: 14px;
  color: #32cd32;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.LinkShopifyYear {
  width: 100%;
  height: 22px;
  position: absolute;
  top: 25px;
  left: 56px;
  font-size: 14px;
  color: #32cd32;
}

/* 绑定店铺按钮*/
.LinkStore {
  width: 100%;
  height: 37px;
  display: flex;
  justify-content: flex-start;
  line-height: 37px;
}

.member_duration {
  margin-top: 8px;
  margin-left: 37px;
  font-size: 14px;
}

.Link_My_Store {
  width: 150px;
  height: 35px;
  margin-top: 15px;
  margin-left: 400px;
  background: #93df89;
  line-height: 35px;
  text-align: center;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
}

.el-table,
.el-table__expanded-cell {
  background-color: none !important;
  color: #fff !important;
}

.el-table th,
.el-table tr {
  border: none !important;
  background-color: none !important;
  color: #fff !important;
}

.pay_items >>> .el-table,
.el-table__expanded-cell {
  background-color: none !important;
  color: #fff !important;
}

.pay_items >>> .el-table th,
.pay_items >>> .el-table tr {
  border: none !important;
  background-color: none !important;
  color: #fff !important;
}

/* 会员支付 */
.payment {
  width: 1320px;
  padding-bottom: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  margin-top: 0px;
}

.left {
  width: 890px;
  height: 650px;
  background: #ffffff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  position: relative;
}
.right {
  background: url('../../../assets/imgs/UpgradeToPro/Upgradetopro\ \(2\).png');
  width: 350px;
  height: 650px;
  padding-left: 10px;
  border-radius: 8px;
  background-size: 100% 100%;
}
.type {
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #333333;
  position: absolute;
  top: 30px;
  left: 38px;
}
.Professional >>> .el-input--suffix .el-input__inner {
  border: 0px;
}

.Professional {
  width: 590px;
  height: 45px;
  line-height: 45px;
  background: rgb(255, 255, 255, 0.3);
  border: 1px solid rgb(204, 204, 204, 0.3);
  border-radius: 15px;
  margin-top: 24px;
  margin-left: 195px;
  overflow: hidden;
  font-size: 15px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #666666;
}

.Recommended {
  font-size: 12px;
  position: absolute;
  top: -10px;
  right: 5px;
  color: #32cd32;
}
.Recommended_discount {
  font-size: 12px;
  position: absolute;
  top: 2px;
  right: 25px;
  color: #32cd32;
}
.Recommended_two {
  font-size: 12px;
  position: absolute;
  top: -11px;
  right: 30px;
  color: #32cd32;
}
.Recommended_season {
  font-size: 12px;
  position: absolute;
  top: 0px;
  right: 30px;
  color: #32cd32;
}
.RecommendedTwo {
  font-size: 12px;
  position: absolute;
  top: 5px;
  right: 32px;
  color: #32cd32;
}

.for_membership {
  font-size: 12px;
  position: absolute;
  top: 3px;
  right: 22px;
  color: #32cd32;
}

::v-deep .el-input--suffix {
  height: 38px;
  border: 0;
  text-align: center;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.radio {
  width: 785px;
  height: 230px;
  margin-left: 35px;
  margin-top: 20px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  font-size: 14px;
}

.radio1 {
  display: block;
  width: 785px;
  height: 75px;
  line-height: 70px;
  border-bottom: 1px solid #f0f0f0;
  padding-left: 54px;
}

::v-deep .el-radio__label {
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #666666;
}

::v-deep .el-button--primary.is-plain {
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #5e5fe1;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

::v-deep .el-radio__inner {
  width: 15px;
  height: 15px;
}

.Month1 {
  display: inline-block;
  height: 15px;
  line-height: 15px;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  position: absolute;
  top: 32px;
  right: 175px;
}

.Month_Mon {
  display: inline-block;
  height: 15px;
  line-height: 15px;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  position: absolute;
  top: 30px;
  right: 220px;
}

.Month_Price {
  display: inline-block;
  height: 15px;
  line-height: 15px;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  position: absolute;
  top: 30px;
  right: 320px;
  text-decoration: line-through;
  color: red;
}

.radio2 {
  display: block;
  width: 785px;
  height: 75px;
  line-height: 75px;
  border-bottom: 1px solid #f0f0f0;
  padding-left: 54px;
}

.Season1 {
  display: inline-block;
  height: 15px;
  line-height: 12px;
  padding-left: 22px;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  position: absolute;
  top: 18px;
  right: 155px;
}
.Season1_price_text {
  display: inline-block;
  height: 15px;
  line-height: 12px;
  padding-left: 22px;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  position: absolute;
  top: 33px;
  right: 155px;
}
.Season_price_text {
  display: inline-block;
  height: 15px;
  line-height: 12px;
  padding-left: 22px;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  position: absolute;
  top: 33px;
  right: 155px;
}

.Season_RealPrice {
  display: inline-block;
  height: 15px;
  line-height: 15px;
  padding-left: 22px;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  position: absolute;
  top: 33px;
  right: 177px;
}

.Season_Price {
  display: inline-block;
  height: 15px;
  line-height: 15px;
  padding-left: 22px;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  position: absolute;
  top: 33px;
  right: 318px;
  text-decoration: line-through;
  color: red;
}

.Season2 {
  padding-left: 120px;
  display: inline-block;
  height: 15px;
  line-height: 30px;
  font-size: 12px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  /* color: #d5d4d4; */
  position: absolute;
  top: 20px;
  left: 40px;
  color: #000;
}

.radio3 {
  display: block;
  width: 785px;
  height: 75px;
  line-height: 75px;
  padding-left: 54px;
}

.Year1 {
  display: inline-block;
  height: 15px;
  line-height: 15px;
  padding-left: 41px;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  position: absolute;
  top: 33px;
  right: 188px;
}
.Year1_discount {
  display: inline-block;
  height: 15px;
  line-height: 15px;
  padding-left: 41px;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  position: absolute;
  top: 33px;
  right: 160px;
}
.Year_one {
  display: inline-block;
  height: 15px;
  line-height: 15px;
  padding-left: 41px;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  position: absolute;
  top: 33px;
  right: 163px;
}
.Year_price_text {
  display: inline-block;
  height: 15px;
  line-height: 15px;
  padding-left: 41px;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  position: absolute;
  top: 33px;
  right: 163px;
}

.Year_RealPrice {
  display: inline-block;
  height: 15px;
  line-height: 15px;
  padding-left: 41px;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  position: absolute;
  top: 33px;
  right: 190px;
}

.Year_Price {
  display: inline-block;
  height: 15px;
  line-height: 15px;
  padding-left: 41px;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  position: absolute;
  top: 33px;
  right: 307px;
  text-decoration: line-through;
  color: red;
}

.YearTwo {
  padding-left: 101px;
  display: inline-block;
  height: 25px;
  line-height: 35px;
  font-size: 12px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  position: absolute;
  top: 19px;
  left: 30px;
  /*color: #d5d4d4;
  color:#696969;*/
  color: #000;
}

.method {
  margin-left: 36px;
  margin-top: 25px;
  height: 17px;
  line-height: 17px;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #333333;
}

.btn {
  margin-top: 18px;
  margin-left: 230px;
  display: flex;
  width: 629px;
}

.pay:nth-child(2) {
  margin-left: 48px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

.paypal {
  display: inline-block;
  padding: 0;
  width: 195px;
  height: 64px;
  background: #ffffff;
  border-radius: 8px;
  font-size: 15px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #585858;
  border: none;
}

.paypal img {
  vertical-align: middle;
  padding-right: 45px;
  width: 100%;
}

::v-deep .paypal:focus,
.paypal:hover,
.selectedType.paypal {
  /* border: .125rem solid #5e5fe1;
  color: #585858;
  font-family: "Regular";
  -webkit-font-smoothing: antialiased;
  transform: scale(1.2);*/
  opacity: 0.5;
  background: #fff;
}

.Information {
  width: 285px;
  height: 37px;
  line-height: 37px;
  border-bottom: 1px solid #ffffff;
  font-size: 15px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #ffffff;
  position: relative;
  padding-left: 26px;
}

.Information i {
  /*padding-left: .625rem;
  padding-right: 1.25rem;*/
  font-size: 18px;
  position: absolute;
  top: 15px;
  left: 0;
}

.head {
  width: 285px;
  height: 240px;
  line-height: 61px;
  border-bottom: 1px solid #ffffff;
  position: relative;
}

.message {
  width: auto;
  height: 42px;
  line-height: 42px;
  margin-left: 8px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  color: #ffffff;
}

.message span {
  margin-top: -11px;
}

.Credit_Card {
  width: 262px;
  height: 42px;
  line-height: 42px;
  margin-left: 8px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  color: #ffffff;
}

.Credit_Card span:nth-child(2) {
  display: block;
  position: absolute;
  top: 0;
  left: 104px;
}

.member_purchase {
  position: absolute;
  top: 31px;
  left: 125px;
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.member_membership {
  position: absolute;
  top: 75px;
  left: 125px;
}

.member_opening {
  position: absolute;
  bottom: 81px;
  left: 125px;
}

.member_subtotal {
  position: absolute;
  bottom: 38px;
  left: 125px;
}

.total_saved span:nth-child(2) {
  color: red;
}

.expiration {
  margin-top: 30px;
  margin-left: 8px;
  height: 15px;
  line-height: 15px;
}

.expiration span:nth-child(1) {
  display: inline-block;
  font-size: 12px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  color: #ffffff;
}

.expiration p {
  padding-left: 0;
  font-size: 12px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  color: #ffffff;
}

.price {
  margin-top: 35px;
  margin-left: 103px;
  height: 23px;
  font-size: 22px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  color: #ffffff;
  line-height: 23px;
}

/* 提现弹出框 */
.paybox {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
}

.pay_content {
  width: 488px;
  height: 337px;
  box-shadow: 0 0 36px 0 rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  overflow: hidden;
  position: absolute;
  background-color: #f9fafb;
  left: calc(50% - 191px);
  top: calc(50% - 231px);
}

.pay_title {
  width: 100%;
  height: 56px;
  line-height: 56px;
  font-size: 16px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #333333;
  padding-left: 24px;
  padding-right: 22px;
  border-bottom: 1px solid #e4e6ef;
}

.pay_title span {
  width: 12px;
  height: 12px;
  display: block;
  color: #9fa1aa;
  background-color: #9fa1aa;
  background: url('http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715927766175_close.png')
    no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  float: right;
  margin-top: 24px;
  cursor: pointer;
}

.pay_items {
  width: 100%;
  height: 338px;
}

::v-deep .el-table__header-wrapper {
  display: none;
}

.pay_left {
  width: 152px;
  padding-left: 15px;
  font-size: 12px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  color: #585858;
}

.pay_left span {
  display: block;
  width: 152px;
}

.paybtn {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 69px;
  line-height: 69px;
  text-align: center;
}

.paybtn button {
  width: 138px;
  height: 36px;
  background: #f9fafb;
  border: 1px solid #6788ff;
  border-radius: 8px;
}

/* 新增信用卡弹出框 */
.creditcard {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
}

.creditcard .card_content {
  width: 412px;
  height: 285px;
  box-shadow: 0 0 36px 0 rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  overflow: hidden;
  position: absolute;
  background-color: #f9fafb;
  left: calc(50% - 241px);
  top: calc(50% - 153px);
}

.creditcard .card_title {
  width: 100%;
  height: 56px;
  line-height: 56px;
  font-size: 16px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  color: #333333;
  padding-left: 24px;
  padding-right: 22px;
  border-bottom: 1px solid #e4e6ef;
}

.card_title span {
  width: 11px;
  height: 12px;
  display: block;
  color: #9fa1aa;
  background-color: #9fa1aa;
  background: url('http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715927766175_close.png')
    no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  float: right;
  margin-top: 24px;
  cursor: pointer;
}

.card_item {
  width: 100%;
  height: 36px;
  line-height: 36px;
  margin-top: 18px;
  position: relative;
}

.Separation {
  position: absolute;
  top: 33px;
  left: 66px;
  font-size: 15px;
  color: #ccc;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}

::v-deep .card_right .el-input {
  width: 112px;
  height: 36px;
  background: #f9fafb;
  border-bottom: 1px solid #ccc !important;
  border: 0;
  position: absolute;
  top: -41px;
  left: 210px;
}

::v-deep .card_Right .el-input .el-input__inner {
  width: 322px;
  height: 36px;
  /*background: #f9fafb;*/
  background: none !important;
  border-bottom: 1px solid #ccc !important;
  border: none;
  position: absolute;
  top: 0;
  left: -90px;
}

.card_right1 >>> .el-form {
  width: 105px;
  display: inline-block;
}

.card_right1 >>> .el-form-item {
  width: 105px;
  display: inline-block;
}

.card_right1 >>> .el-input {
  width: 140px;
  margin-top: -60px;
}

.card_right1 {
  display: inline-block;
  margin-left: 41px;
}

::v-deep .card_right1 .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 150px;
  margin-left: 40px;
}

::v-deep .el-form-item__content {
  line-height: 40px;
  position: relative;
  font-size: 14px;
  margin-top: 50px;
}

::v-deep .card_right1 .el-input .el-input__inner {
  /* display: inline-block;*/
  width: 70px;
  height: 48px;
  background: #f9fafb;
  /* border: .0625rem solid #E4E6EF;
  border-radius: .625rem;*/
  border-bottom: 1px solid #ccc !important;
  border: none;
}

::v-deep .card_right1 span:nth-child(1) .el-input {
  width: 70px;
  height: 48px;
  border-bottom: 1px solid #ccc !important;
  border: none;
  border-radius: 0;
}

::v-deep .card_right1 span:nth-child(2) .el-input {
  width: 70px;
  height: 48px;
  border-bottom: 1px solid #ccc !important;
  border: none;
  margin-left: 30px;
  border-radius: 0;
}

.cardbtn {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 92px;
  line-height: 92px;
  border-top: 1px solid #e4e6ef;
}

.cardbtn button {
  width: 138px;
  height: 40px;
  background: #f9fafb;
  border-radius: 10px;
}

.cardbtn .Return {
  margin-left: 70px;
}

.cardbtn .added {
  margin-left: 136px;
  background-color: #6788ff;
  color: #ffffff;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  border: 1px solid #6788ff !important;
}
/* 新增信用卡弹出框 */
.changeCellStyle {
  background: none !important;
}

.DaysTrialBoxMessage {
  width: 95%;
  height: 40px;
  margin-top: 20px;
  margin-left: 8px;
  background: #fff;
  border-radius: 8px;
}

.DaysTrialBox {
  color: #32cd32;
  border-radius: 0.5rem;
  line-height: 2.1875rem;
  padding-left: 0;
  padding-right: 0.625rem;
  font-size: 18px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
}
</style>
<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex_start {
  justify-content: flex-start;
}
.payment {
  .payWays {
    display: flex;
    height: 60px;
    align-items: flex-end;
    justify-content: space-around;
    padding: 0 30px;
    margin-top: 40px;
    .card {
      width: 260px;
      height: 50px;
      background: #d3dfff;
      border-radius: 25px;
      background-repeat: no-repeat;
      background-position: center;
      @extend .flex;
      img {
        height: 80%;
      }
    }
    .checkbox::v-deep .el-checkbox__input {
      margin-top: -25px;
      margin-right: 10px;
      &.is-checked {
        .el-checkbox__inner {
          background-color: #3f6aff;
          border-color: #3f6aff;
        }
      }
    }
    .choosed {
      background: #3f6aff;
    }
  }
}
</style>
