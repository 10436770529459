import request from '@/utils/request'

export const GetMyRechargeList = (data) =>
  request({
    method: 'post',
    url: '/api/Recharge/GetMyRechargeList',
    data
  })
// 获取余额流水
export const GetMyBalanceLogList = (data) =>
  request({
    method: 'post',
    url: '/api/Member/GetMyBalanceLogList',
    data
  })
// 导出余额流水
export const ExportMyBalanceLogList = (data) =>
  request({
    method: 'post',
    url: '/api/Member/ExportMyBalanceLogList',
    data
  })
export const GetMyCreditLimitRegItemList = (data) =>
  request({
    method: 'post',
    url: '/api/LhMemberCreditLimitRegItem/List',
    data
  })
export const GetBalanceWithdrawalList = (data) =>
  request({
    method: 'post',
    url: '/api/Member/GetBalanceWithdrawalList',
    data
  })
export const ExportBalanceWithdrawalList = (data) =>
  request({
    method: 'post',
    url: '/api/Member/ExportBalanceWithdrawalList',
    data
  })
export const GetOrderPaymentList = (data) =>
  request({
    method: 'post',
    url: '/api/BusinessOrder/GetOrderList',
    data
  })
/* 获取退款记录列表 */
export const GetRefundRecordList = (data) =>
  request({
    method: 'post',
    url: '/api/DLZBusiness/GetRefundRecordList',
    data
  })
/* 导出退款记录列表 */
export const ExportRefundRecordList = (data) =>
  request({
    method: 'post',
    url: '/api/DLZBusiness/ExportRefundRecordList',
    data
  })
/* 获取毛利列表 */
export const GetGrossProfitList = (data) =>
  request({
    method: 'post',
    url: '/api/DLZBusiness/GetGrossProfitList',
    data
  })
/* 导出获取毛利列表 */
export const ExportGrossProfitList = (data) =>
  request({
    method: 'post',
    url: '/api/DLZBusiness/ExportGrossProfitList',
    data
  })
/* payment退款列表 */
export const PaymentRefundRegisterList = (data) =>
  request({
    method: 'post',
    url: '/api/LhBusinessRefundRegister/List',
    data
  })

/* 导出payment退款列表 */
export const ExportPaymentRefundRegisterList = (data) =>
  request({
    method: 'post',
    url: '/api/LhBusinessRefundRegister/Export',
    data
  })

/* 导出payment订单列表 */
export const ExportBusinessOrderList = (data) =>
  request({
    method: 'post',
    url: '/api/BusinessOrder/ExportBusinessOrderList',
    data
  })

/* Available退款列表 */
export const ShopAvailableCollectionList = (data) =>
  request({
    method: 'post',
    url: '/api/BusinessOrder/ShopAvailableCollection',
    data
  })

/* 导出Available退款列表 */
export const ShopAvailableCollectionExport = (data) =>
  request({
    method: 'post',
    url: '/api/BusinessOrder/ShopAvailableCollectionExport',
    data
  })

/* Available总额 */
export const ShopAvailableTotal = (data) =>
  request({
    method: 'post',
    url: '/api/BusinessOrder/ShopAvailable',
    data
  })

export const CusProfitCollection = (data) =>
  request({
    method: 'post',
    url: '/api/BusinessOrder/CusProfitCollection',
    data
  })

export const CusProfitCollectionExport = (data) =>
  request({
    method: 'post',
    url: '/api/BusinessOrder/CusProfitCollectionExport',
    data
  })
