<script>
import { baseApiHost } from '@/utils/env'
import { SaleProblemsSolved, SaveReplyByCustomer } from '@/api'

export default {
  name: 'FeedProcessResult',
  props: {
    afterSaleDetail: Object
  },
  data() {
    return {
      showReplyDialog: false,
      currentAfterDealType: [],
      action: baseApiHost() + '/api/File/UploadImgage',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('Authorization')
      },
      formModel: {
        CustomerOrderId: null,
        Reply: null,
        ArrayFileUrl: [],
        lastProcessResult: []
      },
      loading: false
    }
  },
  watch: {
    afterSaleDetail: {
      handler(val) {
        if (val) {
          this.formModel.CustomerOrderId = val.CustomerOrderId
          this.formModel.lastProcessResult = val.LastProcessResult || []
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {},
  methods: {
    onProblemSolved() {
      SaleProblemsSolved(this.formModel.CustomerOrderId).then((res) => {
        console.log(res, '1111')
        this.$router.push({ name: 'SupportTicket' })
        /*  if (res && res.Result) {
            const { ProcessingStatusInt } = res.Result
            if (ProcessingStatusInt === 1) {

            } else {
            // this.SupportTicket()
            }
          } */
      })
    },
    handleRemove(file, fileList) {
      const index = this.formModel.ArrayFileUrl.findIndex(
        (item) => item.Url === file.response.Result.FullFileName
      )
      this.formModel.ArrayFileUrl.splice(index, 1)
    },
    handleUploadSuccess(res, file) {
      console.log(res)
      const result = res.Result[0] || {}
      if (result.UploadStatus) {
        this.formModel.ArrayFileUrl.push(result.FullFileName)
      }
    },
    onConfirm() {
      this.$refs.formRef?.validate((valid) => {
        if (valid) {
          this.loading = true
          SaveReplyByCustomer(this.formModel)
            .then((res) => {
              if (res.Success) {
                this.showReplyDialog = false
                this.$emit('onReplySuccess')
                this.$message.success('Confirm Success')
              }
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          // this.$message.error('Some filed is Empty!')
          return false
        }
      })
    }
  }
}
</script>

<template>
  <div class="feed-process-result">
    <div class="header">
      <span class="title">{{ $t('SupportTicket.ProcessResult') }}</span>
      <el-button
        size="small"
        round
        style="background: #3f6aff; border: none"
        type="primary"
        @click="showReplyDialog = true"
      >
        {{ $t('SupportTicket.Reply') }}
      </el-button>
      <el-button size="small" round type="primary" @click="onProblemSolved">
        {{ $t('SupportTicket.ProblemSolved') }}
      </el-button>
    </div>
    <span class="reply-title"> Reply：{{ formModel.lastProcessResult.Reply || '-' }} </span>
    <span class="reply-content-title">{{ $t('SupportTicket.Feedback') }}:</span>
    <el-input disabled type="textarea" :value="formModel.lastProcessResult.Feedback" rows="3" />
    <!--    <span class="reply-content-footer">-->
    <!--      {{ $t('SupportTicket.NewTrackingCode') }}-->
    <!--    </span>-->

    <el-dialog :visible.sync="showReplyDialog" width="40%" show-close>
      <el-form ref="formRef" label-position="top" :model="formModel">
        <el-form-item
          :label="$t('SupportTicket.Reply')"
          prop="Reply"
          :rules="{
            required: true,
            message: 'Please Input Reply',
            trigger: 'blur'
          }"
        >
          <el-input type="textarea" v-model="formModel.Reply" :rows="4"></el-input>
        </el-form-item>

        <el-form-item :label="$t('SupportTicket.ImageUrl')">
          <el-upload
            :action="action"
            list-type="picture-card"
            :headers="headers"
            :on-remove="handleRemove"
            :limit="20"
            :on-success="
              (res, file) => {
                handleUploadSuccess(res, file)
              }
            "
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :loading="loading" class="button-close" round @click="showReplyDialog = false">
          {{ $t('SupportTicket.Close') }}
        </el-button>
        <el-button
          :loading="loading"
          class="button-confirm"
          round
          type="primary"
          @click="onConfirm"
        >
          {{ $t('SupportTicket.Confirm') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
.feed-process-result {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    min-height: 56px;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 16px;

    .title {
      flex: 1;
      font-size: 18px;
      font-weight: 500;
      text-align: left;
      color: #15104b;
    }
  }

  .reply-title {
    font-size: 14px;
    font-weight: 500;
  }

  .reply-content-title {
    font-size: 14px;
    font-weight: 500;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .reply-content-footer {
    font-size: 12px;
    font-weight: 500;
    margin-top: 8px;
  }
}

.dialog-footer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  align-items: center;

  .button-close {
    width: 200px;
    height: 44px;
    border: 1px solid #15104b;
    color: #15104b;
  }

  .button-confirm:hover {
    background: #110570;
  }

  .button-confirm {
    width: 200px;
    height: 44px;
    background: #15104b;
    border: none;
  }
}

:deep(.el-dialog) {
  border-radius: 20px;
}

:deep(.el-dialog__body) {
  padding: 10px 16px;
}

:deep(.el-dialog__header) {
  padding: 0;
}
</style>
