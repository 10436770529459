var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "particulars" }, [
    _c("div", { staticClass: "activeName" }, [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("span", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("SupportTicket.OrderDetail"))),
          ]),
          _c(
            "el-button",
            { attrs: { type: "text" }, on: { click: _vm.returnToSystem } },
            [_vm._v(" " + _vm._s(_vm.$t("SupportTicket.ReturnToSystem")) + " ")]
          ),
          _c("i", { staticClass: "el-icon-arrow-right icon-color" }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "container-layout" },
        [
          _c(
            "el-form",
            {
              attrs: {
                "label-position": "top",
                model: _vm.afterSaleDetail,
                disabled: "",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("SupportTicket.OrderNumber") },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.afterSaleDetail.SalesRecord,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.afterSaleDetail,
                                  "SalesRecord",
                                  $$v
                                )
                              },
                              expression: "afterSaleDetail.SalesRecord",
                            },
                          }),
                          _c("span", { staticClass: "tracking-number" }, [
                            _vm._v(
                              _vm._s(_vm.$t("SupportTicket.TrackingNumber")) +
                                " :  " +
                                _vm._s(
                                  _vm.afterSaleDetail?.LogisticTransitNo || "-"
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("SupportTicket.QuestionType"),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "--Filter By Type Of Problem--",
                                clearable: "",
                                disabled: "",
                              },
                              model: {
                                value: _vm.afterSaleDetail.ProblemTypeCode,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.afterSaleDetail,
                                    "ProblemTypeCode",
                                    $$v
                                  )
                                },
                                expression: "afterSaleDetail.ProblemTypeCode",
                              },
                            },
                            _vm._l(_vm.afterProblemType, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              ![0, 1].includes(_vm.afterSaleDetail.ProblemTypeCode)
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("SupportTicket.DealType"),
                        prop: "DealType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "calc(50% - 10px)" },
                          attrs: {
                            placeholder: "--Select The Appropriate--",
                            clearable: "",
                          },
                          model: {
                            value: _vm.afterSaleDetail.DealType,
                            callback: function ($$v) {
                              _vm.$set(_vm.afterSaleDetail, "DealType", $$v)
                            },
                            expression: "afterSaleDetail.DealType",
                          },
                        },
                        _vm._l(_vm.afterDealType, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("SupportTicket.ProblemDescription") },
                },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 2 },
                    model: {
                      value: _vm.afterSaleDetail.Description,
                      callback: function ($$v) {
                        _vm.$set(_vm.afterSaleDetail, "Description", $$v)
                      },
                      expression: "afterSaleDetail.Description",
                    },
                  }),
                ],
                1
              ),
              [5, 7].includes(_vm.afterSaleDetail.ProblemTypeCode) &&
              [1, 2].includes(_vm.afterSaleDetail.DealType)
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("SupportTicket.ContactPostReason"),
                        prop: "ContactPostReason",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          value:
                            _vm.afterSaleDetail.ContactAttachedData
                              ?.ContactPostReason,
                          type: "textarea",
                          rows: 2,
                          readonly: "",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(_vm.currentTemplate, {
            ref: "templateRef",
            tag: "component",
            attrs: {
              "data-source": _vm.orderDetail,
              "show-error-product": _vm.showErrorProduct,
              "img-upload-path": _vm.afterSaleDetail.TempPath,
              "after-sale-detail": _vm.afterSaleDetail,
              "country-list": _vm.countryList,
              readonly: "",
            },
          }),
          _vm.afterSaleDetail.ProcessingStatusInt ==
          _vm.ProcessingStatus.WaitConfirmed
            ? _c("FeedProcessResult", {
                attrs: { "after-sale-detail": _vm.afterSaleDetail },
                on: { "on-reply-success": _vm.onReplySuccess },
              })
            : _vm._e(),
          _c("FeedOrderDetail", { attrs: { "data-source": _vm.orderDetail } }),
          _c("FeedHistoryRecord", {
            ref: "historyRef",
            attrs: {
              "order-id": _vm.afterSaleDetail.OrderId,
              "customer-order-id": _vm.afterSaleDetail.CustomerOrderId,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }