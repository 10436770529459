<script>
import FeedOrderDetail from '@/views/SupportTicket/widgets/FeedOrderDetail.vue'
import FeedHistoryRecord from '@/views/SupportTicket/widgets/FeedHistoryRecord.vue'
import FeedProcessResult from '@/views/SupportTicket/widgets/FeedProcessResult.vue'
import FeedTemplate1 from '@/views/SupportTicket/template/FeedTemplate1.vue'
import FeedTemplate2 from '@/views/SupportTicket/template/FeedTemplate2.vue'
import FeedTemplate3 from '@/views/SupportTicket/template/FeedTemplate3.vue'
import FeedTemplate4 from '@/views/SupportTicket/template/FeedTemplate4.vue'
import FeedTemplate5 from '@/views/SupportTicket/template/FeedTemplate5.vue'
import FeedTemplate6 from '@/views/SupportTicket/template/FeedTemplate6.vue'

import { ProcessingStatus, SupportTicketDict } from '@/views/SupportTicket/dict'
import { GetOrderAndAddressAndDetail, queryAfterSaleDetail } from '@/api'

export default {
  name: 'FileQuestionDetail',
  computed: {
    ProcessingStatus() {
      return ProcessingStatus
    }
  },
  components: {
    FeedProcessResult,
    FeedHistoryRecord,
    FeedOrderDetail,
    FeedTemplate1,
    FeedTemplate2,
    FeedTemplate3,
    FeedTemplate4,
    FeedTemplate5,
    FeedTemplate6
  },
  data() {
    return {
      currentTemplate: 'FeedTemplate1',
      afterProblemType: SupportTicketDict.afterProblemType,
      afterDealType: SupportTicketDict.afterDealType,
      afterSaleDetail: {
        CustomerOrderId: this.$route.query.customerOrderId,
        OrderId: null,
        OrderNo: null,
        ProblemTypeCode: null,
        DealType: null,
        Description: null,
        ProcessingStatusInt: -1,
        TempPath: `Upload/Images/TempPath/orderProblem/${
          this.$store.state.member.memberInfo?.MemberId
        }/${new Date().getTime()}`
      },
      orderDetail: {},
      showErrorProduct: false,
      countryList: []
    }
  },
  watch: {
    'afterSaleDetail.ProblemTypeCode': function (newValue) {
      if ([9, 10, 11, 13].includes(newValue)) {
        this.currentAfterDealType = this.afterDealType.slice(0, 2)
      } else {
        this.currentAfterDealType = this.afterDealType
      }
      //是补发问题并且几种特殊的问题类型要展示地址信息
      let isReplacement = this.afterSaleDetail.DealType === 2 && [2, 3, 5, 6, 7].includes(newValue)
      if (newValue === 0 || isReplacement) {
        this.currentTemplate = 'FeedTemplate2'
        this.showErrorProduct = newValue === 0
      } else if (newValue === 9) {
        this.currentTemplate = 'FeedTemplate3'
      } else if (newValue === 10) {
        this.currentTemplate = 'FeedTemplate4'
      } else if (newValue === 11) {
        this.currentTemplate = 'FeedTemplate5'
      } else if (newValue === 13) {
        this.currentTemplate = 'FeedTemplate6'
      } else {
        this.currentTemplate = 'FeedTemplate1'
      }
    },
    'formModel.DealType': function (newValue) {
      if (newValue) {
        //是补发问题并且几种特殊的问题类型要展示地址信息
        let isReplacement =
          newValue === 2 && [2, 3, 5, 6, 7].includes(this.afterSaleDetail.ProblemTypeCode)
        if (isReplacement) {
          this.currentTemplate = 'FeedTemplate2'
          this.showErrorProduct = false
        } else {
          if (this.currentTemplate === 'FeedTemplate2') {
            this.currentTemplate = 'FeedTemplate1'
          }
        }
      }
    }
  },
  mounted() {
    this.requestAfterSaleDetail()
  },
  methods: {
    requestAfterSaleDetail() {
      let loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
        target: '.mainContent'
      })
      queryAfterSaleDetail({
        customerOrderId: this.afterSaleDetail.CustomerOrderId
      })
        .then((res) => {
          if (res.Success) {
            this.afterSaleDetail = {
              ...res.Result
            }
            this.requestOrderAndAddressAndDetail()
          }
        })
        .finally(() => {
          loading.close()
        })
    },
    requestOrderAndAddressAndDetail() {
      GetOrderAndAddressAndDetail(this.afterSaleDetail.OrderId).then((res) => {
        if (res.Success) {
          this.orderDetail = res.Result || {}
          this.orderDetail.Address = {
            Id: this.orderDetail.AddressId,
            Contact: this.orderDetail.Contact,
            Email: this.orderDetail.Email,
            MobilePhone: this.orderDetail.MobilePhone,
            TelePhone: this.orderDetail.TelePhone,
            Company: this.orderDetail.Company,
            Country: this.orderDetail.CountryCode,
            Province: this.orderDetail.Province,
            City: this.orderDetail.City,
            Area: this.orderDetail.Area,
            Address: this.orderDetail.Address,
            Address2: this.orderDetail.Address2,
            ZipCode: this.orderDetail.ZipCode,
            HouseNumber: this.orderDetail.HouseNumber,
            VatNumber: this.orderDetail.VatNumber,
            CountryName: this.orderDetail.CountryName,
            CountryNameEn: this.orderDetail.CountryNameEn,
            FullAddress: this.orderDetail.FullAddress
          }
        }
      })
    },
    returnToSystem() {
      this.$router.replace({ name: 'SupportTicket' })
    },
    onReplySuccess() {
      this.requestAfterSaleDetail()
      this.$refs.historyRef.requestHistoryTwo()
    }
  }
}
</script>

<template>
  <div class="particulars">
    <div class="activeName">
      <div class="header">
        <span class="title">{{ $t('SupportTicket.OrderDetail') }}</span>
        <el-button type="text" @click="returnToSystem">
          {{ $t('SupportTicket.ReturnToSystem') }}
        </el-button>
        <i class="el-icon-arrow-right icon-color"></i>
      </div>
      <div class="container-layout">
        <el-form label-position="top" :model="afterSaleDetail" disabled>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item :label="$t('SupportTicket.OrderNumber')">
                <el-input v-model="afterSaleDetail.SalesRecord"></el-input>
                <span class="tracking-number"
                  >{{ $t('SupportTicket.TrackingNumber') }} :&nbsp;
                  {{ afterSaleDetail?.LogisticTransitNo || '-' }}
                </span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('SupportTicket.QuestionType')">
                <el-select
                  style="width: 100%"
                  v-model="afterSaleDetail.ProblemTypeCode"
                  placeholder="--Filter By Type Of Problem--"
                  clearable
                  disabled
                >
                  <el-option
                    v-for="item in afterProblemType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item
            :label="$t('SupportTicket.DealType')"
            prop="DealType"
            v-if="![0, 1].includes(afterSaleDetail.ProblemTypeCode)"
          >
            <el-select
              style="width: calc(50% - 10px)"
              v-model="afterSaleDetail.DealType"
              placeholder="--Select The Appropriate--"
              clearable
            >
              <el-option
                v-for="item in afterDealType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('SupportTicket.ProblemDescription')">
            <el-input v-model="afterSaleDetail.Description" type="textarea" :rows="2"></el-input>
          </el-form-item>
          <el-form-item
            v-if="
              [5, 7].includes(afterSaleDetail.ProblemTypeCode) &&
              [1, 2].includes(afterSaleDetail.DealType)
            "
            :label="$t('SupportTicket.ContactPostReason')"
            prop="ContactPostReason"
          >
            <el-input
              :value="afterSaleDetail.ContactAttachedData?.ContactPostReason"
              type="textarea"
              :rows="2"
              readonly
            ></el-input>
          </el-form-item>
        </el-form>
        <component
          ref="templateRef"
          :is="currentTemplate"
          :data-source="orderDetail"
          :show-error-product="showErrorProduct"
          :img-upload-path="afterSaleDetail.TempPath"
          :after-sale-detail="afterSaleDetail"
          :country-list="countryList"
          readonly
        />
        <FeedProcessResult
          v-if="afterSaleDetail.ProcessingStatusInt == ProcessingStatus.WaitConfirmed"
          :after-sale-detail="afterSaleDetail"
          @on-reply-success="onReplySuccess"
        />
        <FeedOrderDetail :data-source="orderDetail" />
        <FeedHistoryRecord
          ref="historyRef"
          :order-id="afterSaleDetail.OrderId"
          :customer-order-id="afterSaleDetail.CustomerOrderId"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.particulars {
  width: 100%;
  height: auto;
  background: #eff1f3;
  padding-bottom: 32px;
}

.activeName {
  width: 1320px;
  border-radius: 10px;
  line-height: 20px;
  background: white;

  .header {
    display: flex;
    min-height: 56px;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    border-bottom: 1px solid #d9d9d9;

    .title {
      flex: 1;
      font-size: 18px;
      font-weight: 500;
      text-align: left;
      color: #15104b;
    }

    .icon-color {
      color: #15104b;
      font-weight: bold;
      font-size: 14px;
    }
  }

  .container-layout {
    padding: 16px 40px;

    .tracking-number {
      color: #666;
      font-size: 13px;
    }

    :deep(.el-form-item) {
      margin-bottom: 10px;
    }

    :deep(.el-input__inner) {
      border-radius: 37px;
      //background: #f1f2f4;
      //border: none;
    }

    :deep(.el-textarea__inner) {
      border-radius: 10px;
      //background: #f1f2f4;
      //border: none;
    }

    :deep(.el-input--suffix) {
      border-radius: 37px;
      //background: #f1f2f4;
    }
  }

  .image-upload {
    display: flex;
    align-items: center;
    margin-top: -24px;

    .image-upload-tips {
      font-size: 12px;
      color: red;
      padding-right: 10px;
    }

    .button {
      color: red;
      font-size: 12px;
    }
  }
}

:deep(.el-input.is-disabled .el-input__inner) {
  color: #606266 !important;
  border: none !important;
  background: #f1f2f4 !important;
}

:deep(.el-textarea.is-disabled .el-textarea__inner) {
  color: #606266 !important;
  border: none !important;
  background: #f1f2f4 !important;
}
</style>
