var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "email" }, [
    _c("div", { staticClass: "StepOneBox" }, [
      _c("div", { staticClass: "step-box" }, [
        _c("div", { staticClass: "steps" }, [
          _c(
            "div",
            { staticClass: "step", class: [_vm.step === 1 ? "active" : ""] },
            [
              _c("p", { staticClass: "step_name" }, [
                _vm._v(_vm._s(_vm.$t("register.name1"))),
              ]),
            ]
          ),
          _c("div", { staticClass: "step_line" }),
          _c(
            "div",
            { staticClass: "step", class: [_vm.step === 2 ? "active" : ""] },
            [
              _c("p", { staticClass: "step_name" }, [
                _vm._v(_vm._s(_vm.$t("register.name2"))),
              ]),
            ]
          ),
        ]),
        _vm.step === 1
          ? _c(
              "div",
              { staticClass: "step-form" },
              [
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "rule-form",
                    attrs: { model: _vm.ruleForm, rules: _vm.rules },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "option", attrs: { prop: "fullname" } },
                      [
                        _c(
                          "div",
                          { staticClass: "option-inner" },
                          [
                            _c("p", { staticClass: "REQUIRED" }, [_vm._v("*")]),
                            _c("el-input", {
                              staticClass: "option-inner-input",
                              attrs: {
                                placeholder: _vm.$t("register.fullname"),
                                "auto-complete": "off",
                              },
                              model: {
                                value: _vm.ruleForm.fullname,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "fullname",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.fullname",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      { staticClass: "option", attrs: { prop: "email" } },
                      [
                        _c(
                          "div",
                          { staticClass: "option-inner" },
                          [
                            _c("p", { staticClass: "REQUIREDTwo" }, [
                              _vm._v("*"),
                            ]),
                            _c("el-input", {
                              staticClass: "option-inner-input",
                              attrs: {
                                placeholder: _vm.$t("register.email"),
                                "auto-complete": "off",
                              },
                              model: {
                                value: _vm.ruleForm.email,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "email",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.email",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "option",
                        attrs: { prop: "marketingCode" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "option-inner" },
                          [
                            _c("p", { staticClass: "REQUIRED" }),
                            _c("el-input", {
                              staticClass: "option-inner-input",
                              attrs: {
                                placeholder: _vm.$t("Invitation code"),
                                "auto-complete": "off",
                              },
                              model: {
                                value: _vm.ruleForm.marketingCode,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "marketingCode",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.marketingCode",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("el-form-item", [
                      _c(
                        "div",
                        {
                          staticClass: "next-btn btn-bg0",
                          on: { click: _vm.nextStep },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("register.next")) + " ")]
                      ),
                      _c("p", { staticClass: "login" }, [
                        _c("span", [_vm._v(_vm._s(_vm.$t("login.login")))]),
                        _c(
                          "span",
                          { staticClass: "change", on: { click: _vm.loginIn } },
                          [_vm._v(" " + _vm._s(_vm.$t("register.Sign")))]
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.step === 2
          ? _c("div", { staticClass: "step-two" }, [
              _c("div", { staticClass: "order-quantity" }, [
                _c("p", { staticClass: "order-quantity-des" }, [
                  _vm._v("Let us help you to scale your business!"),
                ]),
                _c(
                  "div",
                  { staticClass: "how-many" },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "order-quantity-select",
                        attrs: { placeholder: "How many orders daily?" },
                        model: {
                          value: _vm.valueOrder,
                          callback: function ($$v) {
                            _vm.valueOrder = $$v
                          },
                          expression: "valueOrder",
                        },
                      },
                      _vm._l(_vm.optionOrders, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          staticClass: "order-quantity-select-option",
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "select-box" }, [
                _c("div", { staticClass: "select-box-quantity" }, [
                  _c(
                    "div",
                    { staticClass: "select" },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "select-inner",
                          attrs: {
                            filterable: "",
                            "filter-method": _vm.dataFilter,
                            placeholder: _vm.$t("register.select"),
                          },
                          on: { change: _vm.ChangeFlag },
                          model: {
                            value: _vm.selectCode,
                            callback: function ($$v) {
                              _vm.selectCode =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "selectCode",
                          },
                        },
                        [
                          _c("template", { slot: "prefix" }, [
                            _c("i", {
                              class: _vm.selectedClass,
                              staticStyle: { "margin-top": "15px" },
                            }),
                          ]),
                          _vm._l(_vm.optionsCopy, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item.Id,
                                staticStyle: {
                                  color: "#000",
                                  "font-family": "Regular",
                                  "-webkit-font-smoothing": "antialiased",
                                },
                                attrs: {
                                  label: "(" + "+" + item.PhoneCode + ")",
                                  value: item.Id,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      float: "left",
                                      color: "#8492a6",
                                      "font-size": "13px",
                                      "font-family": "Regular",
                                      "-webkit-font-smoothing": "antialiased",
                                    },
                                  },
                                  [
                                    _c("i", { class: item.NationalFlag }),
                                    _vm._v(
                                      "  " +
                                        _vm._s(
                                          item.Name +
                                            "(+" +
                                            item.PhoneCode +
                                            ")"
                                        )
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.mobileNumber,
                        expression: "mobileNumber",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "mobile-number",
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("register.phone"),
                    },
                    domProps: { value: _vm.mobileNumber },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.mobileNumber = $event.target.value.trim()
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "icon-info",
                      on: {
                        mouseenter: function ($event) {
                          return _vm.enter(_vm.e)
                        },
                        mouseleave: function ($event) {
                          return _vm.leave()
                        },
                      },
                    },
                    [_c("i", { staticClass: "el-icon-info" })]
                  ),
                  _vm.displayUSAdropInfo
                    ? _c(
                        "div",
                        {
                          staticClass: "display-info",
                          on: {
                            mouseleave: function ($event) {
                              return _vm.leave()
                            },
                          },
                        },
                        [
                          _c("p", [
                            _vm._v(
                              "Your privacy is very important to USAdrop and your"
                            ),
                          ]),
                          _c("p", [
                            _vm._v("infomation will never be shared or sold."),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c("p", { staticClass: "please-enter-phone" }, [
                  _vm._v("Please enter phone number to complete registration"),
                ]),
              ]),
              _c("div", { staticClass: "code-box" }, [
                _c("div", { staticClass: "code-box-group" }, [
                  _c(
                    "div",
                    { staticClass: "code-input" },
                    [
                      _c("el-input", {
                        staticClass: "code-input-verifyCode",
                        attrs: {
                          maxlength: "6",
                          oninput: "value=value.replace(/^\\.+|[^\\d.]/g,'')",
                          placeholder: _vm.$t("register.code"),
                        },
                        model: {
                          value: _vm.verifyCode,
                          callback: function ($$v) {
                            _vm.verifyCode =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "verifyCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "code-send c-pointer col-col5",
                      on: { click: _vm.getVerifyCode },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("register.send")) + " ")]
                  ),
                  _vm.displayShowCode
                    ? _c(
                        "div",
                        {
                          ref: "child",
                          staticClass: "pay-box",
                          on: { click: _vm.closeBtn },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "ValidationBox" },
                            [
                              _c("Validation", {
                                attrs: {
                                  phoneNum: _vm.mobileNumber,
                                  phoneCode: _vm.selectCode,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "unable-verification" }, [
                    _c(
                      "p",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.displayBtn()
                          },
                        },
                      },
                      [_vm._v("No Code?")]
                    ),
                  ]),
                ]),
                _vm.DisplayUnable
                  ? _c("div", { staticClass: "unable-receive-two" }, [
                      _c("p", [_vm._v("Emails us:")]),
                      _c("p", [_vm._v("info@usadrop.com")]),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "div",
                { staticClass: "info" },
                [
                  _c("el-input", {
                    staticClass: "info-input",
                    attrs: {
                      maxlength: "20",
                      type: "password",
                      "show-password": "",
                      placeholder: _vm.$t("register.password"),
                      autocomplete: "new-password",
                    },
                    model: {
                      value: _vm.password,
                      callback: function ($$v) {
                        _vm.password =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "password",
                    },
                  }),
                ],
                1
              ),
              _c(
                "p",
                { staticClass: "agree" },
                [
                  _c("el-checkbox", {
                    attrs: { type: "checkbox" },
                    model: {
                      value: _vm.agreeChecked,
                      callback: function ($$v) {
                        _vm.agreeChecked =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "agreeChecked",
                    },
                  }),
                  _vm._v(" " + _vm._s(_vm.$t("register.agree")) + " "),
                  _c(
                    "a",
                    {
                      staticClass: "Agree1",
                      attrs: {
                        href: "https://www.usadrop.com/Terms/",
                        target: "_blank",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("register.user")))]
                  ),
                  _vm._v(" " + _vm._s(_vm.$t("register.and")) + " "),
                  _c(
                    "a",
                    {
                      staticClass: "Agree1",
                      attrs: {
                        href: "https://www.usadrop.com/privacy/",
                        target: "_blank",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("register.privacy")))]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "step-btn" }, [
                _c(
                  "div",
                  {
                    staticClass: "last-btn btn-bg6 col-col1",
                    on: { click: _vm.backStep },
                  },
                  [_vm._v(_vm._s(_vm.$t("register.last")))]
                ),
                _c(
                  "div",
                  {
                    staticClass: "next-btn btn-bg0",
                    on: { click: _vm.nextStep },
                  },
                  [_vm._v(_vm._s(_vm.$t("register.nextTow")))]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }