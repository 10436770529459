<template>
  <div :id="id" class="dp-video"></div>
</template>

<script>
import DPlayer from 'dplayer'
import { UpdateVideoProcess } from '@/api/proConnect'
export default {
  props: {
    src: {
      type: String,
      default: '',
      required: true
    },
    // 使用canSeeTime 做会员限制播放时间 '' 不限值  number 为限制时间
    canSeeTime: {
      type: [Number, String],
      default: ''
    },
    seekTime: {
      type: [Number, String],
      default: ''
    },
    poster: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'video/mp4'
    },
    id: {
      type: String,
      default: 'dplayer'
    },
    videoId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      dp: null,
      lastKnownTime: 0, // 用于记录上次的播放时间
    }
  },
  methods: {
    initVideo() {
      this.dp = new DPlayer({
        container: document.getElementById(this.id),
        video: {
          url: this.src,
          pic: this.poster,
          type: this.type
        }
      })

      this.dp.on('ended', this.handleEnded)

      if (typeof this.seekTime === 'number') {
        this.dp.seek(this.seekTime)
      }

      this.dp.on('loadeddata', () => {
        this.handleTimeupdate() // 调用时间更新逻辑
      })

      this.dp.on('timeupdate', this.handleTimeupdate)
      this.dp.on('seeked', this.handleSeeked)
      this.dp.on('play', this.handlePlay)

      // 启动10秒定时器
      //this.startProcessInterval()
    },
    startProcessInterval() {
      this.intervalId = setInterval(() => {
        const currentTime = this.dp.video.currentTime
        /* this.getVideoProcess(currentTime) */
        if (currentTime > this.lastKnownTime) {
          this.getVideoProcess(currentTime);
          this.lastKnownTime = currentTime; // 更新已知的时间
        }
      }, 10000)
    },
    stopProcessInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId)
        this.intervalId = null
      }
    },
    handleEnded() {
      this.$emit('videoEnded') // 触发父组件事件
    },
    handleTimeupdate() {
      const currentTime = this.dp.video.currentTime
      //this.getVideoProcess(currentTime)
      /* 处理会员权限 */
      if (this.canSeeTime !== '' && currentTime > this.canSeeTime) {
        if (currentTime > this.canSeeTime) {
          this.dp.pause()
          try {
            document.exitFullscreen();  // 尝试退出全屏
          } catch (e) {
            console.error("退出全屏失败:", e);  // 捕获错误并输出
          }
          /* document.exitFullscreen() */
          this.$emit('canSeePause')
        }
        return
      }
    },
    handleSeeked() {
      const currentTime = this.dp.video.currentTime
      /* 处理会员权限 */
      if (this.canSeeTime !== '' && currentTime > this.canSeeTime) {
        if (currentTime > this.canSeeTime) {
          this.dp.pause()
          try {
            document.exitFullscreen();  // 尝试退出全屏
          } catch (e) {
            console.error("退出全屏失败:", e);  // 捕获错误并输出
          }
          //document.exitFullscreen()
          this.$emit('canSeePause')
        }
        return
      }
    },
    getVideoProcess(currentTime) {
      const totalTime = this.dp.video.duration // 获取视频总时长
      if (totalTime > 0) {
        // 确保总时长大于零
        const percentage = Math.round((currentTime / totalTime) * 100) // 计算百分比
        let postData = {
          VideoId: this.videoId,
          Process: percentage // 传递百分比
        }
        UpdateVideoProcess(postData).then((res) => {
          if (res.Success) {
            // 处理成功逻辑
          }
        })
      } else {
      }
    },
    // 播放
    handlePlay() {
      this.startProcessInterval()
      this.$emit('play')
    },
    getTotalTime(callback) {
      const interval = setInterval(() => {
        let totalTime = this.dp.video.duration
        totalTime = isNaN(totalTime) ? 0 : totalTime
        if (totalTime > 0) {
          clearInterval(interval)
          callback(totalTime)
        }
      }, 100) // 每 100 毫秒检查一次 gtag 是否已加载
    },
    /**
     * 跳转指定的播放时间
     * @param percent 百分比
     */
    seekTo(percent) {
      this.getTotalTime((totalTime) => {
        const seekTime = totalTime * percent
        this.dp.seek(seekTime)
        this.dp.play()
      })
    }
  },
  mounted() {
    this.initVideo()
  },
  beforeDestroy() {
    this.stopProcessInterval() // 组件销毁时清除定时器
  }
}
</script>

<style scoped>
.dp-video {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}
</style>
